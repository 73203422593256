body {
  margin: 0;
  padding: 0;
  font-family: 'Avenir', Helvetica, sans-serif;
  min-height: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 48px;
}

html {
  background: #ffffff;
}

